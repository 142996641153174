import React from 'react';
import { Redirect } from 'react-router-dom';
import { getFromStorage } from '../../helpers/StorageManager';
import routes from '../../helpers/Routes';

class ProtectedUserRoutes extends React.Component{
  redirect = () => <Redirect to = {routes.home}/>

  isLoggedIn = () => getFromStorage('user') !== null;

  render() {
    if(this.isLoggedIn())
      return <this.props.next {...this.props}/>
    return this.redirect();
  }

}

export default ProtectedUserRoutes;