import { Link } from 'react-router-dom';
import React from 'react';
import Logo from './assets/Mylan_Logo.png';

const MastHead = () => <div className="top_head">
  <Link to="/">
    <img src={Logo} width="60%" alt="brand logo" />
  </Link>
</div>;

export default MastHead;