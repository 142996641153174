export const AdminLoginStyles = {
  root: {
    height: '100vh',
    background: '#8274c5',
    padding: 0,
    paddingTop: '5rem',
    fontFamily: 'Roboto'
  },
  authCard : {
    background: '#fff',
    boxShadow: '1px 9px 19px #3333334f',
    borderRadius: '.3rem'
  },
  authCardHeader: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    background: '#ececec'
  },
  authCardTitle: {
    background: '#4e4288',
    color: '#fff',
    padding: '2rem',
    borderRadius: ' 0.3rem .3rem 0 0'
  },
  authBoxes: {
    padding: '2rem 2rem 3rem 2rem'
  },
  roboto: {
    fontFamily: 'Roboto'
  },
  signInBtn: {
    marginTop: '1rem',
    padding: '1rem'
  }
}

export const AdminPanelStyles =(theme) => ({
  container: {
    height: '100vh',
    padding: 0,
    background: '#333'
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: '#009688',
    color: '#fff'
  }
});
