import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Tab, Tabs } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './AdminPanel.css';
import TabPanel from '../../components/tab-panel/TabPanel';
import CustomTable from '../../components/micro-analytics/CustomTable';
import { getFromStorage, purgeFromStorage } from '../../helpers/StorageManager';
import routes from '../../helpers/Routes';
import { AdminPanelStyles } from '../../styles/AdminStyles';
import { FirebaseContext } from '../../firebase';
import { getTime } from '../../helpers/Utils';
import { analyticsHeaders, usersHeaders } from '../../helpers/AppConstants';

const AdminPanelPage = (props) => {
  return (
    <div>
      <FirebaseContext.Consumer>
        {firebase => <AdminPanel firebase={firebase} classes={props.classes} />}
      </FirebaseContext.Consumer>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class AdminPanel extends Component{

  constructor(props) {
    super(props);
    this.state  = {
      activeTab: 0,
      analyticsDataList: [],
      usersDataList: [],
      loggedIn: getFromStorage('admin') !== ''
    }
    this.getDataFromFirebase()
  }

  handleChange = (event, newValue) => {
    this.setState(state => ({
      ...state,
      activeTab: newValue
    }));
  };

  getDataFromFirebase = () => {
    const { firebase } = this.props;
    const db = firebase.firestore;
    const analyticsRef = db.collection('analytics').orderBy('start_time', 'desc');
    analyticsRef.onSnapshot(analyticsData => {
      const analyticsDataList = [];
      analyticsData.forEach(doc => {
        analyticsDataList.push(doc.data());
      })
      this.setState({
        ...this.state,
        analyticsDataList
      })
    });

    const userRef = db.collection('users').orderBy('entryTime', 'desc');
    userRef.onSnapshot(usersData => {
      const usersDataList = [];
      usersData.forEach(user => usersDataList.push(user.data()));
      this.setState({
        ...this.state,
        usersDataList
      })
    })
  }

  signOut = () => {
    purgeFromStorage('admin');
    this.setState({
      ...this.state,
      loggedIn: false
    })
  }

  render() {
    const {classes} = this.props;
    const {activeTab, analyticsDataList, usersDataList, loggedIn} = this.state;

    /// ------------------------PROCESSING DATA (refactor required) --------------------///
    const analyticsData = analyticsDataList.map((ele) => ({
      ...ele,
      start_time: getTime(ele.start_time),
      end_time: getTime(ele.end_time)
    }))

    const usersData = usersDataList.map((ele) => ({
      ...ele,
      entryTime: getTime(ele.entryTime),
    }))

    const analyticsDataSorted = JSON.parse(JSON.stringify(analyticsData, ['name', 'email', 'action', 'asset', 'start_time', 'end_time']))
    const usersDataSorted = JSON.parse(JSON.stringify(usersData, ['name', 'email', 'speciality', 'phone', 'address', 'entryTime']))

    if(!loggedIn)
      return <Redirect to={routes.adminLogin}/>

    return (
      <Container maxWidth='xl' className={classes.container}>
      <>
        <div className={classes.root}>
          <AppBar position="static" className={classes.appBar} color='#4CB8C4'>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Admin Panel
              </Typography>
              <Button color="inherit" onClick={this.signOut}>SIGN OUT</Button>
            </Toolbar>
            <Tabs value={activeTab} onChange={this.handleChange} aria-label="simple tabs example">
              <Tab label="Micro Analytics" {...a11yProps(0)} />
               <Tab label="Users" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <TabPanel value={activeTab} index={0}>
              <CustomTable
                headers= {analyticsHeaders}
                data={analyticsDataSorted}
                context='Analytics'
                field='start_time'
              />
            </TabPanel>
             <TabPanel value={activeTab} index={1}>
               <CustomTable
                 headers= {usersHeaders}
                 data={usersDataSorted}
                 context='Users'
                 field='entryTime'
               />
             </TabPanel>
          </Grid>
        </Grid>
      </>
      </Container>
    );
  }
}

export default withStyles(AdminPanelStyles)(AdminPanelPage);