import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { FirebaseContext } from '../../firebase';
import MastHead from '../masthead/Masthead';
import BottomStreak from '../bottom-streak/BottomStreak';
import AuthStyles from '../../styles/AuthStyles';
import { getFromStorage, setToStorage } from '../../helpers/StorageManager';
import ToastNotification from '../toasters/ToastNotification';
import {emailRegex} from '../../helpers/AppConstants';

const LoginPage = (props) => {
  return (
    <div>
      <FirebaseContext.Consumer>
        {firebase => <Login firebase={firebase} classes={props.classes} />}
      </FirebaseContext.Consumer>
    </div>
  );
}

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      valid: '',
      errors: {
        email: ''
      },
      dbError: '',
      loggedIn: ''
    }
  }

  handleChange = (e) => {
    const change = {[e.target.name]: e.target.value};
    this.setState(change);
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      dbError: ''
    })
  }
  
  handleBlur = (e) => {
    const {name, value} = e.target;
    this.validate(name, value);
  }

  validate = (name, value) => {
    const {errors} = this.state;
    switch (name) {
      case 'email':
        if(value !== '') {
          errors.email = (!emailRegex.test(String(value).toLowerCase())) ?
            'Please enter a valid email id' : '';
        } else {
          errors.email = 'Please enter this required field';
        }
        break;
    }

    this.setState({...this.state, errors});
  }

  validateForEmpty = (user) => {
    // eslint-disable-next-line no-restricted-syntax
    for(const field of Object.keys(user)) {
      this.validate(field, user[field]);
    }
  }

  validateForm = user => {
    let valid = true;

    this.validateForEmpty(user);

    const {errors} = this.state;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));

    return valid;
  };

  addAnalytics = () => {
    const {firebase} = this.props;

    const user = getFromStorage('user');
    const analyticsData = {
      name: user.name,
      email: user.email,
      start_time: Date.now(),
      end_time: '',
      asset: 'Environment',
      action: 'visited again'
    }

    firebase.publishAnalytics(analyticsData);
  }

  login = (e) => {
    e.preventDefault();
    const { firebase } = this.props;
    const { email} = this.state;

    const valid = this.validateForm({ email });

    if (valid) {

      firebase.doLoginInWithEmailAndPassword(email, '123456').then(async (res) => {
        const data = await firebase.getUserById(res.user.uid);
        setToStorage('user', data)
        this.addAnalytics();
        this.setState({
          ...this.state,
          loggedIn: true
        })
      }).catch((err) => {
        const dbError = err.message;
        this.setState({
          ...this.state,
          dbError
        })
      });
    }
  }

  render() {

    const {classes} = this.props;
    const {errors, loggedIn, dbError} = this.state;

    if(loggedIn)
      return <Redirect to='/experience'/>

    return (
        <div>
          <Container maxWidth='md' className='mt-5'>
            <Grid container>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <MastHead/>
                  <Paper square='true' className={classes.stripe}>
                    <Typography variant='h5' align='center'>
                      Login to Viatris Booth
                    </Typography>
                  </Paper>
                  <div className="text-center" style={{ padding: '6%' }}>
                    <form noValidate autoComplete="off" onSubmit={this.login}>
                      <TextField
                        name='email'
                        label="Please enter your registered email: "
                        fullWidth
                        margin='normal'
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        helperText={errors.email}
                        error={errors.email !== ''}
                      />
                      <Button className='mt-3'
                              variant="contained"
                              color="primary"
                              disableElevation
                              onClick={this.login}
                      >
                        Proceed
                      </Button>
                    </form>
                    <p className='mt-3'>
                      If you are not registered yet please{' '}
                      <Link to="/register">register</Link>
                    </p>
                  </div>
                  <BottomStreak/>
                </Paper>
              </Grid>
            </Grid>
          </Container>

          {
            dbError && <ToastNotification
              show={(dbError !== '')}
              message={dbError}
              handleClose={this.handleClose} />
          }
        </div>
    );
  }
}

export default withStyles(AuthStyles)(LoginPage);
