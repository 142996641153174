import React from 'react';
import { Container, LinearProgress, withStyles, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LoadingScreenStyles from '../../styles/LoadingScreenStyles';
import ViatrisLogo from './assets/ViatrisLogo.png';

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" style={{color: '#a7a7a7'}}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class LoadingScreen extends React.Component {

  render() {

    const { classes, show, loadedPercent } = this.props;

    return (
      <div>
        {show && <Container maxWidth='xl' className= {`d-flex justify-content-center align-items-center ${classes.root}`}>
         <Grid container className={`justify-content-center ${classes.loading}`}>
           <Grid item xs={12} md={12} className='text-center mb-2'>
             <img className={classes.logo} src={ViatrisLogo} alt='Viatris logo'/>
           </Grid>
           <Grid item xs={6} md={4}>
             <LinearProgressWithLabel value={Number(loadedPercent)}/>
           </Grid>
         </Grid>
        </Container> }
      </div>
    );
  }
}

export default withStyles(LoadingScreenStyles)(LoadingScreen);