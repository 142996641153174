export const setToStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const getFromStorage = (key) => {
  const data = localStorage.getItem(key);
  return  data && JSON.parse(data);
}

export const purgeFromStorage = (key) => {
  localStorage.removeItem(key)
}
