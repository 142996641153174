import React, { Component } from 'react';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import { FirebaseContext } from '../../firebase';
import MastHead from '../masthead/Masthead';
import { emailRegex, phoneRegex, specialityChoices } from '../../helpers/AppConstants';
import AuthStyles from '../../styles/AuthStyles';
import BottomStreak from '../bottom-streak/BottomStreak';
import { getFromStorage, setToStorage } from '../../helpers/StorageManager';
import ToastNotification from '../toasters/ToastNotification';
import routes from '../../helpers/Routes';


const SignUpPage = (props) => {

  return (
    <div>
      <FirebaseContext.Consumer>
        {firebase => <SignUp firebase={firebase} classes={props.classes} />}
      </FirebaseContext.Consumer>
    </div>
  );
}


const initState = {
  name: '',
  email: '',
  phone: '',
  speciality: '',
  address: '',
  consent: false,
  others: '',
  registered: false,
  errors: {
    email: '',
    phone: '',
    name: '',
    speciality: '',
    address: '',
    others: ''
  },
  dbError: '',
}

class SignUp extends Component {

  constructor(props) {
    super(props);
    this.state = {...initState}
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    const change = {[e.target.name]: e.target.value};
    this.setState(change);
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      dbError: ''
    })
  }

  handleBlur = (e) => {
    const {name, value} = e.target;
    this.validate(name, value);
  }


  setChecked = (e) => {
    const change = {[e.target.name]: e.target.checked};
    this.setState(change);
  }

  validate = (name, value) => {
    const {errors} = this.state;
    switch (name) {
      case 'email':
        if(value !== '') {
          errors.email = (!emailRegex.test(String(value).toLowerCase())) ?
            'Please enter a valid email id' : '';
        } else {
          errors.email = 'Please enter this required field';
        }
        break;
      case 'phone':
        if(value !== '') {
          errors.phone = (!phoneRegex.test(String(value).toLowerCase())) ?
            'Please enter a valid phone number' : '';
        } else {
          errors.phone = 'Please enter this required field';
        }
        break;
      case 'name':
        errors.name = (value !== '') ? '' : 'Please enter this required field';
        break;
      case 'speciality':
        errors.speciality = (value !== '') ? '' : 'Please enter this required field';
        if(value !== 'Others') {
          errors.others  = '';
        }
        break;
      case 'address':
        errors.address = (value !== '') ? '' : 'Please enter this required field';
        break;
      case 'others':
        if(this.state.speciality === 'Others') {
          errors.others = (value !== '') ? '' : 'Please enter this required field';
        } else {
          errors.others = '';
        }
    }
    this.setState({...this.state, errors});
  }

  validateForEmpty = (user) => {
    // eslint-disable-next-line no-restricted-syntax
    for(const field of Object.keys(user)) {
      this.validate(field, user[field]);
    }
  }

  validateForm = user => {
    let valid = true;

    this.validateForEmpty(user);

    const {errors} = this.state;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));

    return valid;
  };

  addAnalytics = () => {
    const {firebase} = this.props;

    const user = getFromStorage('user');
    const analyticsData = {
      name: user.name,
      email: user.email,
      start_time: Date.now(),
      end_time: '',
      asset: 'Environment',
      action: 'visited first time'
    }

    firebase.publishAnalytics(analyticsData);
  }

  publishUser = () => {
    const {firebase} = this.props;

    const {
      name,
      email,
      phone,
      address,
      speciality,
      others,
      consent
    } = this.state

    const valid = this.validateForm( {name, email, phone, speciality, address, others});
    if(valid) {

        firebase.doSignUpWithEmailAndPassword(email, '123456', { name, email, phone, speciality, address, consent, entryTime: Date.now() })
          .then(() => {
            setToStorage('user',{ name, email, phone, speciality, address}  );
            this.addAnalytics();
            this.setState({
              ...this.state,
              registered: true
            })
          }).catch((e) => {
          const dbError = e.message;
          this.setState({
            ...this.state,
            dbError
          })
        });
    }
  }

  submitForm = () => {
    const {others} = this.state;
    if(others !== '') {
      this.setState({
        ...this.state,
        speciality: others
      }, () => {
        this.publishUser()
      })
      return;
    }
    this.publishUser()
  }

  render() {

    const {consent, speciality, registered, dbError, errors} = this.state;
    const {classes} = this.props;

    if(registered)
      return <Redirect to={routes.experience}/>

    return (
        <div>
          <Container maxWidth='md' className='mt-5'>
            <Grid container>
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <MastHead/>
                  <Paper square='true' className={classes.stripe}>
                    <Typography variant='h5' align='center'>
                      You are now ready to enter and navigate the booth. Please enter your details to proceed.
                    </Typography>
                  </Paper>
                  <div style={{ padding: '2% 6% 1% 6%' }}>

                    <TextField
                      label="Name: "
                      name='name'
                      fullWidth
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.name}
                      error={errors.name !== ''}
                    />
                    <br/>
                    <FormControl fullWidth className='mt-3 mb-1'>
                      <InputLabel id="demo-simple-select-label">Select Specialty: </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={speciality}
                        name = 'speciality'
                        onChange={this.handleChange}
                        fullWidth
                        onBlur={this.handleBlur}
                        error={errors.speciality !== ''}
                      >
                        {specialityChoices.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    { speciality === 'Others' &&  <TextField
                      label="Please specify "
                      name='others'
                      fullWidth
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.others}
                      error={errors.others !== ''}
                    />}
                    <TextField
                      label="Mobile No.: "
                      name='phone'
                      fullWidth
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.phone}
                      error={errors.phone !== ''}
                    />

                    <TextField
                      label="Email Address: "
                      name='email'
                      fullWidth
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.email}
                      error={errors.email !== ''}
                    />

                    <TextField
                      label="Clinic / Hospital Affiliation :"
                      name = 'address'
                      fullWidth
                      multiline
                      rows={3}
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.address}
                      error={errors.address !== ''}
                    />
                    <div className='mt-3'>
                      <strong>Consent: </strong>
                      <br/>
                      <FormControlLabel
                        className='pb-0 mb-0'
                        control={
                          <Checkbox
                            checked={consent}
                            onChange={this.setChecked}
                            name="consent"
                            color="primary"
                            className='pb-0 mb-0'
                          />
                        }
                        label=""
                      />
                      <span style={{ fontSize: '11px', textAlign: 'left' }}>
                          Yes, I have read the{' '}
                        <a
                          rel="noreferrer"
                          href="https://viatris-digitalassets.s3.eu-central-1.amazonaws.com/legal/Data-protection-notice-International-webinars-Viatris-EN.pdf"
                          target="_blank"
                        >
                            data protection notice
                          </a>{' '}
                        and agree that Viatris (see companies listed in the data
                          protection notice) uses my business contact data to provide
                          me with scientific information and information on
                          pharmaceutical products as well as events, webinars or
                          surveys of Viatris group. In order to improve its emails,
                          websites and information services, Viatris may track whether
                          I received an email, opened it, clicked on any links, viewed
                          or downloaded any documents in it. My consent is voluntary.
                          I can withdraw my consent at any time. The withdrawal of
                          consent will not affect the lawfulness of processing based
                          on consent before its withdrawal.
                        </span>
                    </div>
                    <div className='text-center mt-4'>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.submitForm}
                        disableElevation
                        disabled={!consent}
                      >
                        Submit
                      </Button>
                    </div>

                  </div>
                  <BottomStreak/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          {
            dbError && <ToastNotification
              show={(dbError !== '')}
              message={dbError}
              handleClose={this.handleClose} />
          }
        </div>
    );
  }
}

export default withStyles(AuthStyles)(SignUpPage);