import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Fab } from '@material-ui/core';
import { generateKey, downloadXLS, StyledTableCell, StyledTableRow} from '../../helpers/Utils';
import DownloadDatewise from '../download-datewise/DownloadDateWise';

const TableRows = (props) => {
  const {rows} = props;
  return rows.map((row) =>
    <StyledTableRow key={row.name + generateKey(Math.random())}>
      {Object.keys(row).map((key) => <StyledTableCell align='center'>{row[key]}</StyledTableCell>)}
    </StyledTableRow>);
}

const TableRowHeader = (props) => {
  const {header} = props;
  const headerCells = Object.keys(header).map((key) => <StyledTableCell align='center'>{header[key]}</StyledTableCell>)
  return (<StyledTableRow key={generateKey(Math.random())}>
      {headerCells}
    </StyledTableRow>);
}

class CustomTable extends React.Component{

  constructor(props) {
    super(props);
    this.state ={
      page: 0,
      rowsPerPage: 10,
      dateSelectedForDownload: ''
    }
  }


  handleChange = (e) => {
    const change = {[e.target.name]: e.target.value};
    this.setState(change);
  };


  handleChangePage = (event, newPage) => {
    this.setState({
      ...this.state,
      page: newPage
    })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      ...this.state,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    })
  };

  downloadData = (field) => {
    const {dateSelectedForDownload} =  this.state;
    const {data} = this.props;

    const convertToDateString = (date) => new Date(date).toDateString();

    const downloadData = data.filter(ele => convertToDateString(ele[field]) === dateSelectedForDownload );

    downloadXLS(downloadData);
  }

  downloadAllData = () => {
    const {data} = this.props;
    downloadXLS(data);
  }

  render() {

    const {data, headers, context, field} = this.props;
    const {page, rowsPerPage, dateSelectedForDownload} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const rows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <div>
        <TableContainer component={Paper}>
          <Table  style={{minWidth: '650'}} aria-label="simple table">
            <TableHead>
              <TableRowHeader header={headers}/>
            </TableHead>
            <TableBody>
              <TableRows rows={rows}/>
              {emptyRows > 0 && (
                <TableRow style={{ height: ( 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </TableContainer>
        <DownloadDatewise
          handleChange = {this.handleChange}
          downloadData = {() => this.downloadData(field)}
          buttonName = {context}
          dateSelectedForDownload = {dateSelectedForDownload}
        />
        <Fab color="secondary" aria-label="download" style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem'
        }} onClick={this.downloadAllData}>
          <GetAppIcon />
        </Fab>
      </div>
    );
  }
}

export default CustomTable;