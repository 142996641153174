import React from 'react';
import {Container, Fab, withStyles} from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

import VideoStyles from '../../styles/VideoStyles';

class VideoPanel extends React.Component {

  state = {
    muted: true,
    playVideo: true
  }

  hideVideo = () => {
    this.setState({
      ...this.state,
      playVideo: false
    })
  }

  toggleMute = () => {
    this.setState(prevState => ({
      ...prevState,
        muted: !prevState.muted
    }));
  }

  render() {

    const {classes} = this.props;
    const {playVideo, muted} = this.state;
    const url = 'https://storage.googleapis.com/mylan-pccom.appspot.com/Viatris-Pcom.mp4';

    return (
      <div>
        {playVideo && <Container className={classes.container} maxWidth='xl'>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            src={url}
            autoPlay
            muted={muted}
            className={classes.video}
            preload='auto'
            playsInline
            onEnded={this.hideVideo}
          />
          <Fab
            variant="extended"
            size='medium'
            color="primary"
            aria-label="add"
            className={classes.fabButton}
            onClick={this.hideVideo}
          >
            <SkipNextIcon className={classes.extendedIcon} />
            Skip
          </Fab>
          
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={classes.muteButton}
            onClick={this.toggleMute}
          >
            {
              muted ? <VolumeOffIcon/> : <VolumeUpIcon/>
            }
          </Fab>
        </Container>
        }
      </div>
    );
  }
}
export default withStyles(VideoStyles)(VideoPanel);