import React, { Component } from 'react';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import Fab from '@material-ui/core/Fab';
import CustomModal from '../../../components/modal/CustomModal';

import BabylonScene from './Scene';
import type { SceneEventArgs } from './Scene';
import LoadingScreen from '../../../components/loading-screen/LoadingScreen';
import { FirebaseContext } from '../../../firebase';
import { getFromStorage } from '../../../helpers/StorageManager';
import Home from '../assets/home.png'
import TutorialPopup from '../../../components/tutorial-popup/TutorialPopup';
import VideoPanel from '../../../components/video-panel/VideoPanel';
import { getNameMapping } from '../../../helpers/Utils';

const Viewer = (props) => {
  return (
    <div>
      <FirebaseContext.Consumer>
        {firebase => <ViewerPage firebase={firebase} classes={props.classes} />}
      </FirebaseContext.Consumer>
    </div>
  );
}

class ViewerPage extends Component<{}, {}> {

  constructor(props) {
    super(props);

    this.canvas = '';
    this.engine = '';
    this.scene = '';
    this.camera = '';
    this.light = '';
    this.state = {
      sceneLoadedPercent: 0,
      showLoading: true,
      showModal: false,
      interactables: '',
      url: '',
      currentAnalytics: '',
    };
    this.interactablesData = '';
    this.hotspots = '';
  }

  componentDidMount() {

    const {firebase} = this.props;
    const db = firebase.firestore;
    const interactables = db.collection('studio').doc('interactables');
    interactables.onSnapshot(docSnapshot => {
      const interactablesData = docSnapshot.data();
      this.setState({
        ...this.state,
        interactablesData
      })
    })

    const hotspotsRef = db.collection('studio').doc('hotspots');
    hotspotsRef.onSnapshot(docSnapshot => {
      const hotspots = docSnapshot.data()
      this.setState({
        ...this.state,
        hotspots
      })
    })
  }

  onSceneMount = (e: SceneEventArgs) => {
    const { canvas, scene, engine } = e;

    this.canvas = canvas;
    this.engine = engine;
    this.scene = scene;
    this.setupCamera();
    this.setupLights();
    this.setupStudio();
    // this.addObservable();

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render();
      }
    });
  }

  setupCamera = () => {
    this.camera = new BABYLON.FreeCamera('camera1', new BABYLON.Vector3(0.257, 10, -71.751), this.scene);
    this.camera.setTarget(BABYLON.Vector3.Zero());
    this.camera.attachControl(this.canvas, true);
    this.camera.rotation = new BABYLON.Vector3(0,0,0);
    this.camera.inputs.remove(this.camera.inputs.attached.keyboard)
  }

  setupLights = () => {
    const light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), this.scene);
    light.intensity = 0.7;
  }

  setupStudio = () => {
    const loadingCalc = (data) => {
      const {loaded} = data;
      const sceneLoadedPercent = ((loaded * 100) / 63227060).toFixed();
      const showLoading = !(sceneLoadedPercent === '100');
      this.setState(prevState => ({...prevState, sceneLoadedPercent, showLoading}));

    }
    BABYLON.SceneLoader.ImportMeshAsync('',
      'https://storage.googleapis.com/mylan-pccom.appspot.com/', 'Viatris-PCCOM.glb', this.scene, loadingCalc)
      .then((studio) => {
        this.scene.materials.forEach((mat) => mat.unlit = true);

        this.scene.getMaterialByName('Human').backFaceCulling = false;

        const {meshes} = studio;
        this.makeMeshesInteractable(meshes);

      });
  }

  makeMeshesInteractable = (meshes) => {
    meshes.forEach((mesh) => {
      const {interactablesData, hotspots} = this.state;
      if(Object.keys(interactablesData).includes(mesh.name)) {
        mesh.actionManager = new BABYLON.ActionManager(this.scene);

        mesh.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction({
              trigger: BABYLON.ActionManager.OnLeftPickTrigger
            },
            () => {
              const url = interactablesData[mesh.name]
              this.setState({
                ...this.state,
                url,
                showModal: true
              })
              this.addAnalytics(mesh.name);
            }
          )
        )
      }

      if(Object.keys(hotspots).includes(mesh.name)) {
        mesh.actionManager = new BABYLON.ActionManager(this.scene);

        mesh.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction({
              trigger: BABYLON.ActionManager.OnLeftPickTrigger
            },
            () => {
              const cameraPosition = hotspots[mesh.name].positions;
              const cameraRotation = hotspots[mesh.name].rotation;
              const cameraPosVec = new BABYLON.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z);
              const cameraRotVec = new BABYLON.Vector3(cameraRotation.x, cameraRotation.y, cameraRotation.z);
              // put normalize later
              const fromRotation = this.camera.rotation;
              const toRotation = cameraRotVec;
              const fromPosition = this.camera.position;
              const toPosition = cameraPosVec;
              BABYLON.Animation.CreateAndStartAnimation(
                'camRot',
                this.camera,
                'rotation',
                30,
                30,
                fromRotation,
                toRotation,
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
              );
              BABYLON.Animation.CreateAndStartAnimation(
                'camRot',
                this.camera,
                'position',
                30,
                30,
                fromPosition,
                toPosition,
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
              );
            }
          )
        )
      }
    })
  }

  addAnalytics = (name) => {
    const {firebase} = this.props;

    const user = getFromStorage('user');
    const analyticsData = {
      name: user.name,
      email: user.email,
      start_time: Date.now(),
      end_time: '',
      asset: getNameMapping(name),
      action: 'clicked on'
    }
    firebase.publishAnalytics(analyticsData)
      .then((doc) => {
        this.setState({
          ...this.state,
          currentAnalytics: doc.id
        })
      });
  }

  updateAnalytics = () => {
    const {currentAnalytics} = this.state;
    const {firebase} = this.props;
    firebase.updateAnalytics(currentAnalytics)
      .then(() => {
        this.setState({
          ...this.state,
          currentAnalytics: ''
        })
      });
  }

  showModal = () => {

    const {showModal} = this.state;
    if(showModal) {
      this.updateAnalytics();
    }

    this.setState(prevState => ({
      url: 'https://tawk.to/chat/603df02c385de407571b8982/1evov4cn5#',
      showModal: !prevState.showModal
    }))
  }

  goToHome = () => {
    const fromRotation = this.camera.rotation;
    const toRotation = new BABYLON.Vector3(0, 0, 0);
    const fromPosition = this.camera.position;
    const toPosition =  new BABYLON.Vector3(0.257, 10, -71.751);
    BABYLON.Animation.CreateAndStartAnimation(
      'camRot',
      this.camera,
      'rotation',
      30,
      30,
      fromRotation,
      toRotation,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    BABYLON.Animation.CreateAndStartAnimation(
      'camRot',
      this.camera,
      'position',
      30,
      30,
      fromPosition,
      toPosition,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );
  }

  render() {
    const {sceneLoadedPercent, showLoading, showModal, url} = this.state;

    return (
      <>
        <VideoPanel/>
        <TutorialPopup />
        <BabylonScene onSceneMount={this.onSceneMount}/>
        <LoadingScreen show={showLoading} loadedPercent={sceneLoadedPercent}/>
        {
          !showLoading &&
            <div>
              <Fab color="primary" aria-label="add" size='large' style={{
                position: 'fixed',
                bottom: '1rem',
                right: '1rem'
              }}
                   onClick={this.goToHome}
              >
                <img src={Home} alt='Home icon' style={{width: '24px'}}/>
              </Fab>
              {showModal &&
                <CustomModal show={showModal} url={url} onHide={this.showModal}/>
              }
            </div>
        }
      </>
    )
  }
}

export default Viewer;