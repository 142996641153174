import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './App.css';
import RouterBox from '../router/Router';
import {AppTheme} from '../helpers/AppConstants';
import Firebase, {FirebaseContext} from '../firebase';

const theme = createMuiTheme(AppTheme);


function App() {
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <RouterBox/>
        </div>
      </ThemeProvider>
    </FirebaseContext.Provider>
  );
}

export default App;
