import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Container, Grid, Typography, TextField} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {AdminLoginStyles} from '../../styles/AdminStyles';
import { FirebaseContext } from '../../firebase';
import ToastNotification from '../toasters/ToastNotification';
import routes from '../../helpers/Routes';
import {emailRegex} from '../../helpers/AppConstants';
import { setToStorage } from '../../helpers/StorageManager';


const AdminLoginPage = (props) => {
  return (
    <div>
      <FirebaseContext.Consumer>
        {firebase => <AdminLogin firebase={firebase} classes={props.classes} />}
      </FirebaseContext.Consumer>
    </div>
  );
}

class AdminLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      valid: '',
      errors: {
        email: '',
        password: ''
      },
      dbError: '',
      loggedIn: ''
    }
  }

  handleChange = (e) => {
    const change = {[e.target.name]: e.target.value};
    this.setState(change);
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      dbError: ''
    })
  }

  handleBlur = (e) => {
    const {name, value} = e.target;
    this.validate(name, value);
  }

  validate = (name, value) => {
    const {errors} = this.state;
    switch (name) {
      case 'email':
        if(value !== '') {
          errors.email = (!emailRegex.test(String(value).toLowerCase())) ?
            'Please enter a valid email id' : '';
        } else {
          errors.email = 'Please enter this required field';
        }
        break;
    }

    this.setState({...this.state, errors});
  }

  validateForEmpty = (user) => {
    // eslint-disable-next-line no-restricted-syntax
    for(const field of Object.keys(user)) {
      this.validate(field, user[field]);
    }
  }

  validateForm = user => {
    let valid = true;

    this.validateForEmpty(user);

    const {errors} = this.state;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));

    return valid;
  };

  submitForm = (e) => {
    e.preventDefault();
    const { firebase } = this.props;
    const { email, password} = this.state;

    const valid = this.validateForm({ email });

    if (valid) {

      firebase.doLoginInWithEmailAndPassword(email, password).then(async () => {
        setToStorage('admin', {email, password})
        this.setState({
          ...this.state,
          loggedIn: true
        })
      }).catch((err) => {
        const dbError = err.message;
        this.setState({
          ...this.state,
          dbError
        })
      });
    }
  }

  render() {

    const {classes} = this.props;
    const {errors, loggedIn, dbError, email, password} = this.state;
    const isInvalid = password === '' || email === '';

    if(loggedIn)
      return <Redirect to={routes.admin}/>

    return (
      <div>
        <Container className={classes.root} maxWidth='xl'>
          <Container maxWidth='xs'>
            <Grid container className={classes.authCard}>
              <Grid container>
                <Grid item xs={12} className={classes.authCardTitle}>
                  <Typography variant='h4' className={classes.roboto}>
                    Admin panel
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes.authBoxes}>
                  <form onSubmit={this.submit}>
                    <TextField
                      label="Email id:  "
                      name='email'
                      variant='outlined'
                      fullWidth
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.email}
                      error={errors.email !== ''}
                    />
                    <TextField
                      label="Password "
                      name='password'
                      type='password'
                      fullWidth
                      variant='outlined'
                      margin='normal'
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      helperText={errors.password}
                      error={errors.password !== ''}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.submitForm}
                      fullWidth
                      margin='normal'
                      disableElevation
                      disabled={isInvalid}
                      className={classes.signInBtn}
                    >
                      Sign In
                    </Button>
                  </form>
                </Grid>
              </Grid>
              <Grid container>
                <Typography variant='subtitle1' className='w-100 p-3 text-center'>
                  Powered by XR Central
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Container>
        {
          dbError && <ToastNotification
            show={(dbError !== '')}
            message={dbError}
            handleClose={this.handleClose} />
        }
      </div>
    );
  }
}

export default withStyles(AdminLoginStyles)(AdminLoginPage);
