import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import EntryNotFound from '../exceptions/EntryNotFound';
import {ERROR_MESSAGES} from '../exceptions/ErrorCodes';
import { studioHotspots, studioInteractables } from '../helpers/AppConstants';

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID
};

const config = devConfig;

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.firestore.settings({
      timestampsInSnapshots: true
    });
    app.analytics();

    // this.initDB();
  }

  publishUser = async (user, uid) => {
    const db = this.firestore;
    await db.collection('users').doc(uid).set(user);
  }

  getUserById = async (uid) => {

    const db = this.firestore;
    const user = db.collection('users').doc(uid);
    const data = await user.get();
    if (!data.exists)
      throw EntryNotFound(ERROR_MESSAGES.userNotFound);

    return data.data();
  }

  doLoginInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignUpWithEmailAndPassword = async (email, password, user) => {
    const res = await this.auth.createUserWithEmailAndPassword(email, password);
    await this.publishUser(user, res.user.uid);
  }

  publishAnalytics = async(analyticsData) => {
    const db = this.firestore;
    const doc = await db.collection('analytics').add(analyticsData);
    return doc;
  }

  updateAnalytics = async (docId) => {
    const db = this.firestore;
    const doc = db.collection('analytics').doc(docId).update({
      'end_time': Date.now()
    })
    return doc;
  }

  initDB = async () => {
    const db = this.firestore;
    await db.collection('studio').doc('hotspots').set(studioHotspots);
    await db.collection('studio').doc('interactables').set(studioInteractables)
  }
}

export default Firebase;