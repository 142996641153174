import ErrorCodes from './ErrorCodes';

const EntryNotFound = (message) => {
  const error = new Error(message);
  error.code = ErrorCodes.ENTRY_NOT_FOUND;
  return error;
}

EntryNotFound.prototype = Object.create(Error.prototype);

export default EntryNotFound;