import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Studio from '../containers/experience/Experience';


const UserRouter = () => {
  return (
    <div>
      <Switch>
        <Route component={Studio}/>
      </Switch>
    </div>
  );
};

export default UserRouter;