const AuthStyles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    minHeight: '100vh'
  },
  stripe: {
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: '#6806c2',
    color: '#fff'
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bottomBorder: {
    marginBottom: '-21px',
    width: '100%'
  }
});

export default AuthStyles;