import exportFromJSON from 'export-from-json';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { nameMapping } from './AppConstants';

export const getTime = (seconds) => {
  if(seconds === '')
    return '-'
  const date = new Date(seconds);
  return `${date.toDateString()} ${date.toLocaleTimeString()}`;
}

export const generateKey = (pre) => {
  return `${ pre }_${ new Date().getTime() }`;
}

export const generateChatUrl = (name, email) => {
  return `https://mylan-pdhw-chat.web.app?name=${name}&${email}`
}
export const downloadXLS = (downloadData) => {
  if(downloadData === '' || downloadData === undefined)
    return;
  const fileName = 'download'
  const exportType = 'xls'
  const data  = Array.from(downloadData)
  exportFromJSON({data, fileName, exportType})
}

export const getNameMapping = (name) => {
  return nameMapping[name];
}

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#1d1d1d',
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  body: {
    fontSize: 14
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export const getDateItems = () => {
  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = []
    const theDate = new Date(startDate)
    if(endDate.toLocaleDateString() === startDate.toLocaleDateString())
      endDate = startDate;
    while (theDate < new Date(endDate)) {
      dates = [...dates, new Date(theDate)]
      theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, new Date(endDate)]
    return dates
  }
  const dateList = getDatesBetweenDates(new Date('03/17/21'), new Date());
  return dateList;
}