import React from 'react';
import {Switch, Route} from 'react-router-dom';
import AdminPanelPage from '../containers/admin-panel/AdminPanel';


const AdminRouter = () => {
  return (
    <div>
      <Switch>
        <Route component={AdminPanelPage}/>
      </Switch>
    </div>
  );
};

export default AdminRouter;