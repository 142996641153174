import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import routes from '../helpers/Routes'
import Home from '../containers/home/Home';
import Login from '../components/login/Login';
import SignUpPage from '../components/signup/SignUp';
import AdminLoginPage from '../components/admin-login/AdminLogin';
import ProtectedUserRoutes from '../components/protected-routes/ProtectedUserRoutes';
import Experience from '../components/experience/Experience';
import ProtectedAdminRoutes from '../components/protected-routes/ProtectedAdminRoutes';
import Admin from '../components/admin/Admin';

class RouterBox extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path={routes.home} component={Home}/>
          <Route exact path={routes.login} component={Login}/>
          <Route exact path={routes.signup} component={SignUpPage}/>
          <Route exact path={routes.adminLogin} component={AdminLoginPage}/>
          <Route
            path={routes.experience}
            render = {(props) => <ProtectedUserRoutes {...props} next={Experience}/>} />

          <Route
            path={routes.admin}
            render = {(props) => <ProtectedAdminRoutes {...props} next={Admin}/>} />
        </Switch>
      </Router>
    );
  }
}
export default RouterBox;
