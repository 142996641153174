const VideoStyles = (theme) => ({
  container: {
    height: '100vh',
    padding: 0,
    position: 'fixed',
    zIndex: 2000
  },
  video: {
    width: '-webkit-fill-available',
    height: '-webkit-fill-available',
    backgroundColor: '#000',
    position: 'fixed'
  },
  fabButton: {
    position: 'fixed',
    bottom: '1rem',
    right: '5rem'
  },
  muteButton: {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
})

export default VideoStyles;