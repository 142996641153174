import { Button, Container, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import { getDateItems } from '../../helpers/Utils';

const DownloadDatewise = (props) => {

  const { dateSelectedForDownload, handleChange, downloadData, buttonName } = props;

  return (
    <Container maxWidth='xl' className='mt-3 p-0'>
      <Grid container>
        <Grid item xl={12} sm={12} md={12} lg={12}>
          <Paper className='p-4'>
            <h5>{`Download ${buttonName.toLowerCase()} data by date`} - </h5>
            <Grid container>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl className='w-100'>
                  <InputLabel id="demo-simple-select-label">Select date</InputLabel>
                  <Select
                    name='dateSelectedForDownload'
                    labelId="demo-simple-select-label"
                    value={dateSelectedForDownload}
                    onChange={handleChange}
                  >
                    {getDateItems().map((date) => (
                      <MenuItem key={date.toString()} value={date.toDateString()}>
                        {date.toDateString()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className='p-3'>
                <Button variant="contained" color="primary" onClick={downloadData}>
                  {`Download ${buttonName} data`}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default DownloadDatewise;