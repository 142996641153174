import React, { Component } from 'react';
import Viewer from './scene/Viewer';


class Studio extends Component {

  render () {
    return (
      <div>
        <Viewer/>
      </div>
    )
  }
}

export default Studio;