export const specialityChoices = [
  {
    value: 'Allergology',
    label: 'Allergology'
  },
  {
    value: 'Cardiology',
    label: 'Cardiology'
  },
  {
    value: 'Dermatology',
    label: 'Dermatology'
  },
  {
    value: 'Endocrinology',
    label: 'Endocrinology'
  },
  {
    value: 'Family Medicine',
    label: 'Family Medicine'
  },
  {
    value: 'Gastroenterology',
    label: 'Gastroenterology'
  },
  {
    value: 'General Practice',
    label: 'General Practice'
  },
  {
    value: 'General Surgery',
    label: 'General Surgery'
  },
  {
    value: 'Geriatrics',
    label: 'Geriatrics'
  },
  {
    value: 'Infectious Disease',
    label: 'Infectious Disease'
  },
  {
    value: 'Internal Medicine (General)',
    label: 'Internal Medicine (General)'
  },
  {
    value: 'Nephrology',
    label: 'Nephrology'
  },
  {
    value: 'Neurology',
    label: 'Neurology'
  },
  {
    value: 'Obstetrics Gynecology',
    label: 'Obstetrics Gynecology'
  },
  {
    value: 'Occupational Health',
    label: 'Occupational Health'
  },
  {
    value: 'Oncology',
    label: 'Oncology'
  },
  {
    value: 'Pediatrician',
    label: 'Pediatrician'
  },
  {
    value: 'Physical Medicine and Rehabilitation',
    label: 'Physical Medicine and Rehabilitation'
  },
  {
    value: 'Psychiatry',
    label: 'Psychiatry'
  },
  {
    value: 'Pulmonology',
    label: 'Pulmonology'
  },
  {
    value: 'Rheumatology',
    label: 'Rheumatology'
  },
  {
    value: 'Urology',
    label: 'Urology'
  },
  {
    value: 'Others',
    label: 'Others, please specify: ___________'
  }
];

export const AppTheme = {
  palette: {
    primary: {
      main: '#6806c2'
    },
    secondary: {
      main: '#f44336',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/

export const studioInteractables = {
  'XRC_URL_Pods_LeftPod':'https://tawk.to/chat/604727751c1c2a130d66791a/1f0av49jl',
  'XRC_URL_Pods_RightPod':'https://tawk.to/chat/604727751c1c2a130d66791a/1f0av49jl',
  'XRC_URL_Pedestal_Chat': 'https://tawk.to/chat/604727751c1c2a130d66791a/1f0av49jl',
  'XRC_URL_Screens_LeftScreen':'https://www.shobiziems.com/2021/Mylan/Viatris/hep_corner.html',
  'XRC_URL_Pedestal_Logo':'https://www.shobiziems.com/2021/Mylan/Viatris/Viatris.html',
  'XRC_URL_Screens_MiddleScreen':'https://www.shobiziems.com/2021/Mylan/Viatris/legalon.html',
  'XRC_URL_Screens_RightScreen':'https://www.shobiziems.com/2021/Mylan/Viatris/kube_talks.html'
}

export const studioHotspots = {
  'XRC_Teleport_TeleportPoints_LeftPod':{
    'rotation':{
      'y':0,
      'x':0,
      'z':0
    },
    'positions':{
      'z':-19.988,
      'x':-52.566,
      'y':9.89
    }
  },
  'XRC_Teleport_TeleportPoints_Reception':{
    'rotation':{
      'x':0,
      'z':0,
      'y':0
    },
    'positions':{
      'y':9.89,
      'z':-31.06,
      'x':-0.95
    }
  },
  'XRC_Teleport_TeleportPoints_RightPanel':{
    'rotation':{
      'y':0,
      'x':0,
      'z':0
    },
    'positions':{
      'z':-16.251,
      'y':9.89,
      'x':14.85
    }
  },
  'XRC_Teleport_TeleportPoints_Home':{
    'rotation':{
      'y':0,
      'z':0,
      'x':0
    },
    'positions':{
      'x':0.77,
      'y':10,
      'z':-63.5
    }
  },
  'XRC_Teleport_TeleportPoints_RightPod':{
    'rotation':{
      'x':0,
      'z':0,
      'y':0
    },
    'positions':{
      'z':-19.988,
      'x':54.746,
      'y':9.89
    }
  },
  'XRC_Teleport_TeleportPoints_LeftPanel':{
    'positions':{
      'z':-16.229,
      'x':-16.122,
      'y':9.89
    },
    'rotation':{
      'x':0,
      'z':0,
      'y':0
    }
  }
}

export const nameMapping = {
  'XRC_URL_Pods_LeftPod':'Download Poster Left',
  'XRC_URL_Pods_RightPod':'Download Poster Right',
  'XRC_URL_Pedestal_Chat': 'Reception Chat',
  'XRC_URL_Screens_LeftScreen':'Pain And Inflammation',
  'XRC_URL_Pedestal_Logo':'Reception',
  'XRC_URL_Screens_Kubetalks':'Kubetalks',
  'XRC_URL_Screens_Legalon': 'Legalon',
  'XRC_URL_Screens_RightScreen':'Allergy'
}

export const analyticsHeaders = {
  name: 'Name',
  email: 'Email',
  action: 'Action',
  assets: 'Assets',
  startTime: 'Start Time',
  endTime: 'End Time'
}

export const usersHeaders = {
  name: 'Name',
  email: 'Email',
  speciality: 'Specialty',
  phone: 'Mobile no.',
  address: 'Address',
  entryTime: 'Registration Time'
}
