const routes = {
  home: '/',
  login: '/login',
  signup: '/register',

  admin: '/admin',
  adminLogin: '/admin/login',

  experience: '/experience'
};

export default routes;
